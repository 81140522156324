<template xmlns:b-bind="http://www.w3.org/1999/xhtml">
        <div v-if="getForecastWarningStatus">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="trend-desc" :style="getBannerStyle()" v-on:click="toggleTrendPopup()">
                        <span v-for="(warning, index) in this.getForecastWarningStatus.warnings" v-bind:key="warning.type">
                            <span v-if="warning.type === 'LOW_TEMPERATURE'" class="pictofont">&#xE115;</span>
                            <span v-if="warning.type === 'LOW_FEELS_LIKE'" class="pictofont">&#xE115;</span>
                            <span v-if="warning.type === 'LOW_FEELS_LIKE'" class="pictofont picto-e0b9">&#xE0B9;</span>
                            <span v-if="warning.type === 'HUMIDITY'" class="pictofont">&#xE015;</span>
                            <span v-if="warning.type === 'FOG'" class="pictofont">&#xE113;</span>
                            <span v-if="warning.type === 'STRONG_WIND'" class="pictofont">&#xE110;</span>
                            <span v-if="warning.type === 'AIR_QUALITY'" class="pictofont">&#xE11E;</span>
                            <span :style="getValueStyle(index)">
                                {{warning.description}}
                            </span>
                        </span>
                    </div>
                </div>
        </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";

export default {
    name: "ForecastWarningStatus",
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore', ['getForecastWarningStatus']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        // getForecastTrend: function (newValue) {
        // },
    },
    data() {
        return {
            trendPopupDisplayed: false,
            reliabilityPopupDisplayed: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        getBannerStyle() {
            const color = this.getForecastWarningStatus.color;
            let style = '';
            if (color === 'RED') {
                return style + "color: rgb(236 89 89); background-color: rgb(255 243 243)";
            } else if (color === 'ORANGE') {
                return style + "color: rgb(253 154 27); background-color: rgb(253 247 235)";
            } else if (color === 'GREEN') {
                return style + "color: #4ba74b; background-color: rgb(233 247 218)";
            } else if (color === 'GREY') {
                return style + "color: grey; background-color: rgb(245, 245, 245)";
            }
        },
        getValueStyle(index) {
            return (index + 1) === this.getForecastWarningStatus.warnings.length ? '' : 'margin-right: 8px;';
        },
    },
    components: {
    }
}
</script>

<style scoped>


.trend-desc {
    padding: 4px 17px;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
    height: 31px;
}

@media (max-width: 370px) {
    .trend-desc {
        font-size: 13px;
        padding: 4px 8px;
    }
}

@media (min-width: 371px) and (max-width: 699px) {
    .trend-desc {
        font-size: 13px;
        padding: 4px 8px;
    }
}

@media (min-width: 700px) {
    .trend-desc {
        font-size: 14px;
    }
}

.pictofont {
    font-family: 'PictoFont', sans-serif;
    font-size: inherit;  /* Inherit font size from the surrounding text */
    //vertical-align: middle; /* Align with the middle of the text */
    //line-height: 1; /* Ensure there's no extra spacing */
}

.picto-e0b9 {
    vertical-align: top;
    line-height: 1.3;  /* fine tunes the positioning of this one vertically as it is a bit off if set to default 1 */
    position: relative;
    left: -3px;
    right: -4px;
}

</style>
