<template>
    <div class="container page-container">
        <div class="header">
            <h1>SkyAccuracy</h1>
            <h3>Počasí pro astronomy</h3>
        </div>

        <b-row class="mt-4">
            <b-col sm="6" class="mb-4">
                <b-link to="/predpovedi" class="card-link">
                    <b-card class="my-card shadow">
                        <div class="img-container">
                            <b-card-img :src="require('@/assets/images/home_icons_8_forecasts.jpg')" alt="Forecast Image" class="my-img"></b-card-img>
                        </div>
                        <b-card-body>
                            <b-card-title class="card-title">Srovnání aktuálních předpovědí</b-card-title>
                            <b-card-text>
                                Srovnání nejaktuálnějších hodinových předpovědí pro vámi vybranou lokalitu v České republice.
                            </b-card-text>
                            <b-button to="/predpovedi" variant="warning" class="my-btn">Vyhledat předpovědi</b-button>
                        </b-card-body>
                    </b-card>
                </b-link>
            </b-col>

            <b-col sm="6" class="mb-4">
                <b-link to="/spolehlivost" class="card-link">
                    <b-card class="my-card shadow">
                        <div class="img-container">
                            <b-card-img :src="require('@/assets/images/home_icons_8_table_comparison.jpg')" alt="Reliability Image" class="my-img"></b-card-img>
                        </div>
                        <b-card-body>
                            <b-card-title class="card-title">Aktuální spolehlivost předpovědí oblačnosti</b-card-title>
                            <b-card-text>
                                Srovnání spolehlivosti krátkodobých předpovědí oblačnosti.
                            </b-card-text>
                            <b-button to="/spolehlivost" variant="warning" class="my-btn">Zjistit spolehlivost</b-button>
                        </b-card-body>
                    </b-card>
                </b-link>
            </b-col>

            <b-col sm="6" class="mb-4">
                <b-link to="/spolehlivost" class="card-link">
                    <b-card class="my-card shadow">
                        <div class="img-container">
                            <b-card-img :src="require('@/assets/images/home_icons_8_charts.jpg')" alt="Charts Image" class="my-img"></b-card-img>
                        </div>
                        <b-card-body>
                            <b-card-title class="card-title">Dlouhodobá spolehlivost předpovědí oblačnosti</b-card-title>
                            <b-card-text>
                                Dlouhodobé grafy spolehlivosti krátkodobých předpovědí oblačnosti.
                            </b-card-text>
                            <b-button to="/spolehlivost" variant="warning" class="my-btn">Zobrazit grafy</b-button>
                        </b-card-body>
                    </b-card>
                </b-link>
            </b-col>

            <b-col sm="6" class="mb-4">
                <b-link to="/projekt" class="card-link">
                    <b-card class="my-card shadow">
                        <div class="img-container">
                            <b-card-img :src="require('@/assets/images/home_icons_8_about.jpg')" alt="About Project Image" class="my-img"></b-card-img>
                        </div>
                        <b-card-body>
                            <b-card-title class="card-title">O projektu a datech</b-card-title>
                            <b-card-text>
                                Detaily o tomto projektu a o tom, jak se pracuje s daty z jednotlivých modelů.
                            </b-card-text>
                            <b-button to="/projekt" variant="warning" class="my-btn">Více o projektu</b-button>
                        </b-card-body>
                    </b-card>
                </b-link>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tooltip from "@/components/Tooltip";
import { BButton, BCard, BCardBody, BCardImg, BCardText, BCardTitle, BCol, BLink, BRow } from "bootstrap-vue";

export default {
    name: "Homepage",
    mounted() {
        window.scrollTo(0, 0)
    },
    computed: {
        ...mapGetters([])
    },
    methods: {},
    // eslint-disable-next-line vue/no-unused-components
    components: { Tooltip, BCard, BCardText, BLink, BCardBody, BRow, BCol, BCardImg, BCardTitle, BButton }
}
</script>

<style scoped>
.header {
    padding-top: 30px;
    text-align: center;
    margin-bottom: 45px;
}

.header h1 {
    margin-bottom: 10px;
}

.my-card {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #f8f9fa;
    height: 100%;
}

.my-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.card-link {
    text-decoration: none;
    color: inherit;
}

.card-link:hover, .card-link:focus, .card-link:active {
    color: inherit;
    outline: none;
}

.img-container {
    padding: 15px; /* Add margin around the image */
}

.my-img {
    width: 100%;
    height: 290px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a soft shadow around the image */
}

.card-title {
    font-size: 1.2rem;
    text-align: center;
}

.b-card-text {
    text-align: center;
}

.my-btn {
    display: block;
    margin: 15px auto 0 auto;
    width: 70%;
}
</style>
