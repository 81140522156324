<template>
    <div>

<!--        style="height: 36.8px; vertical-align: middle"-->

        <div style="text-align: center; margin-bottom: 30px; padding: 2px; user-select: none">
            <span v-on:click="onPreviousDayForecast"
                  class="move-day move-day-back date material-icons"
                  :style="getMouseOverLeftBkg"
                  @mouseenter="mouseOverDate('left')"
                  @mouseleave="mouseLeaveDate()">
                arrow_back
            </span>
            <span v-on:click="onTodaysForecast"
                  class="forecast-date date"
                  :style="getMouseOverDateBkg"
                  style="font-weight: 500; cursor: pointer;"
                  @mouseenter="mouseOverDate('date')"
                  @mouseleave="mouseLeaveDate()">
                {{ getDate() }}
            </span>
            <span v-on:click="onNextDayForecast"
                  class="move-day move-day-forward date material-icons"
                  :style="getMouseOverRightBkg"
                  @mouseenter="mouseOverDate('right')"
                  @mouseleave="mouseLeaveDate()">
                arrow_forward
            </span>
        </div>

        <h6 style="margin-bottom: 10px; text-align: center">Celková oblačnost (%)</h6>
        <div ref="tableContainer" class="table-responsive" style="overflow-x: scroll; margin-right: 10px">
            <table style="background-color: white">
                <thead>
                    <tr>
                        <th style="background-color: #727272; color: white; font-weight: bold; text-align: center; min-width: 110px">
                            Model \ Hodina
                        </th>
                        <th v-for="hour in 24" :key="hour" :style="currentHourStyle(hour - 1)"
                            style="background-color: #413f3f; color: white; font-weight: normal; min-width: 25px;">
                            {{ hour - 1 }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sourceCode in getDisplayedSourcesAlphabetically" v-bind:key="sourceCode" >
                        <td nowrap style="background-color: #fcfcfc; vertical-align: bottom" >
<!--                            <div v-if="getForecast(sourceCode)">-->
<!--                                <div class="forecast-row-name d-flex align-items-end align-content-end">-->
<!--                                    <span>{{ getForecast(sourceCode).sourceTitle }}</span>-->
<!--                                    <span v-if="getForecast(sourceCode).awardPosition === 1" class="award">🥇</span>-->
<!--                                    <span v-if="getForecast(sourceCode).awardPosition === 2" class="award">🥈</span>-->
<!--                                    <span v-if="getForecast(sourceCode).awardPosition === 3" class="award">🥉</span>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="forecast-row-name d-flex align-items-end align-content-end">
                                {{ sourceDisplayName(sourceCode) }}
                            </div>
                        </td>
                        <td v-for="hour in 24" :key="hour" v-bind:style="getForecastStyle(getHourForecast(hour - 1, getForecast(sourceCode)))">
                            <div style="height: 5px"></div>
                            <div v-if="getForecast(sourceCode)">
                                {{ getHourForecastClouds(hour - 1, getForecast(sourceCode)) }}
                            </div>
                            <div v-else>
                                -
                            </div>
                            <div v-if="getForecast(sourceCode)" :style="getAccuracyStyle(getHourForecast(hour - 1, getForecast(sourceCode)))"></div>
                            <div v-else style="height: 5px"></div>
                        </td>
                    </tr>
                    <tr style="border-top-width: 3px">
                        <td nowrap style="background-color: #fdfbd8" class="measurements-top">
                            <div class="forecast-row-name" style="text-align: left; margin-left: 5px; margin-right: 10px">
                                ČHMÚ měření
                                <Tooltip text="Průměrná hodnota naměřené oblačnosti" placement="right"></Tooltip>
                            </div>
                        </td>
                        <td v-for="hour in 24" :key="hour" :style="getMeasurementStyle(getHourMeasurement(hour - 1, getDayMeasurements))" class="measurements-top">
                            <div style="height: 5px"></div>
                            <div>
                                {{ getHourMeasurementClouds(hour - 1, getDayMeasurements) }}
                            </div>
                            <div style="height: 5px"></div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>


        <VerifiedForecastsLegend></VerifiedForecastsLegend>

    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";
import {ForecastColors} from "@/config/forecast-colors";
import {Sources} from "@/config/sources";
import VerifiedForecastsLegend from "@/components/verification/VerifiedForecastsLegend.vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'VerifiedForecastTable',
    mixins: [utilsMixin],
    props: {},
    watch: {
        // eslint-disable-next-line no-unused-vars
        getSourceForecasts: function (newValue) {
            console.log("Inside watch for getSourceForecasts ...")
            if (this.scrollAction) {
                // console.log("Scrolling action ...")
                this.scrollAction();
                this.scrollAction = null;
            }
        }
    },
    data() {
        return {
            minDiffForAccuracyStyling: 5,
            mouseDatePosition: null,
            mouseOverDateControlsColor: '#d5d5d5',
            scrollAction: null,
            enabledDates: true
        }
    },
    computed: {
        ...mapGetters('verificationsStore', ['getSourceForecasts', 'getForecastDate', 'getDayMeasurements',
            'todayForecastDisplayed', 'getShowUpdateTime', 'previousDayExists', 'nextDayExists',
            'getForecast', 'getDisplayedForecastDiffType']),
        ...mapGetters('settingsStore', ['getDisplayedSourcesAlphabetically']),
        getMouseOverLeftBkg: function () {
            return this.getMouseOverDateArrowBcg('left');
        },
        getMouseOverRightBkg: function () {
            return this.getMouseOverDateArrowBcg('right');
        },
        getMouseOverDateBkg: function () {
            return this.getMouseOverDateArrowBcg('date');
        },
    },
    methods: {
        ...mapActions('verificationsStore', ['toPreviousDayForecast', 'toNextDayForecast', 'toTodaysForecast']),
        sourceDisplayName(sourceCode) {
            return Sources.displayName(sourceCode);
        },
        sourceCodes() {
            return Sources.getSourceCodes();
        },
        onTodaysForecast() {
// <!--            TODO scroll to now (hour) in this case ...-->
            if (this.enabledDates) {
                this.enabledDates = false;
                setTimeout(() => {
                    this.toTodaysForecast();
                    this.enabledDates = true;
                }, 700)
            }
        },
        onPreviousDayForecast() {
            if (this.enabledDates) {
                this.enabledDates = false;
                if (window.innerWidth >= 1000) {
                    // leave out the scrolling on big strings - only introduces delays
                    this.toPreviousDayForecast();
                    this.enabledDates = true;
                } else {
                    this.$refs.tableContainer.scrollBy({
                        left: -2000,
                        behavior: 'smooth'
                    });
                    setTimeout(() => {
                        // TODO only if not already on the left side ... ???
                        this.toPreviousDayForecast();
                        this.scrollAction = () => this.scrollTable('right');
                        this.enabledDates = true;
                    }, 700)
                }

            }
        },
        onNextDayForecast() {
            if (this.enabledDates) {
                this.enabledDates = false;
                if (window.innerWidth >= 1000) {
                    this.toNextDayForecast();
                    this.enabledDates = true;
                } else {
                    this.$refs.tableContainer.scrollBy({
                        left: 2000,
                        behavior: 'smooth'
                    });
                    setTimeout(() => {
                        this.toNextDayForecast();
                        this.scrollAction = () => this.scrollTable('left');
                        this.enabledDates = true;
                    }, 700)
                }
            }
        },
        scrollTable(direction) {
            const container = this.$refs.tableContainer;
            const scrollAmount = 2000;
            // console.log("BEFORE SCROLL: " + container.scrollLeft);
            if (direction === 'left') {
                // console.log("scrolling ... left" + container.scrollLeft);
                container.scrollLeft -= scrollAmount;
            } else if (direction === 'right') {
                // console.log("scrolling ... right");
                container.scrollLeft += scrollAmount;
            }
            // console.log("AFTER SCROLL: " + container.scrollLeft);
        },
        getDate() {
            // eslint-disable-next-line no-unused-vars
            const date = new Date(this.getForecastDate).toLocaleDateString("cs-CZ");
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            if (date === today.toLocaleDateString("cs-CZ")) {
                return "Dnes";
            } else if (date === yesterday.toLocaleDateString("cs-CZ")) {
                return "Včera";
            } else if (date === tomorrow.toLocaleDateString("cs-CZ")) {
                return "Zítra";
            } else {
                return date;
            }
        },
        getMouseOverDateArrowBcg: function(position) {
            if (this.mouseDatePosition) {
                if (this.mouseDatePosition === position) {
                    if ((position === 'left' && this.previousDayExists) || (position === 'right' && this.nextDayExists) || position === 'date') {
                        return "backgroundColor: " + this.mouseOverDateControlsColor;
                    } else {
                        return "color: red";
                    }
                }
            }
            return "";
        },
        mouseOverDate: function(position) {
            this.mouseDatePosition = position;
        },
        mouseLeaveDate: function() {
            this.mouseDatePosition = null;
        },
        getForecastStyle(hourForecast) {
            if (hourForecast) {
                return this.getStyle(hourForecast.avgForecastCloudTotal, hourForecast.firstAfterUpdate);
            }
            return "";
        },
        getMeasurementStyle(hourMeasurement) {
            if (hourMeasurement) {
                return this.getStyle(hourMeasurement.avgMeasuredCloudTotal);
            }
            return "";
        },
        getStyle(cloudTotal, updated) {
            let updatedStyle = "";
            if (updated && this.getShowUpdateTime === 'true') {
                // updatedStyle = "font-weight: 700";
                updatedStyle = "border-left: 2px solid #252522";
            }
            if (cloudTotal || cloudTotal === 0) {
                const clouds = cloudTotal === 100 ? 99 : cloudTotal;
                let background = ForecastColors.shadesOfBlue[Math.floor(clouds / 10)];
                return "backgroundColor: " + background + "; " + updatedStyle
            }
            return updatedStyle;
        },
        currentHourStyle(hour) {
            if (this.isDisplayedToday()) {
                let current = new Date().getHours() === hour;
                if (current) {
                    return "background-color: yellow" + "; text-align: center; color: black";
                }
            }
            return "text-align: center";
        },
        getHourForecast(hour, forecast) {
            if (forecast && forecast.hourlyForecasts) {
                return forecast.hourlyForecasts.find(h => h.hourInt === hour);
            }
            return null;
        },
        getHourForecastClouds(hour, forecast) {
            if (forecast) {
                let hourly = this.getHourForecast(hour, forecast);
                if (hourly) {
                    return hourly.avgForecastCloudTotal
                } else {
                    return "-";
                }
            }
            return null;
        },
        getHourMeasurement(hour, measurements) {
            if (measurements) {
                return measurements.find(h => h.hourInt === hour);
            }
            return null;
        },
        getHourMeasurementClouds(hour, measurements) {
            let measurement = this.getHourMeasurement(hour, measurements);
            if (measurement) {
                return measurement.avgMeasuredCloudTotal
            } else {
                return "-";
            }
        },
        getAccuracyStyle(hour) {
            if (this.getDisplayedForecastDiffType === 'diff') {
                return this.getAccuracyStylePlain(hour);
            } else if (this.getDisplayedForecastDiffType === 'diff-abs') {
                return this.getAccuracyStyleAbs(hour);
            } else {
                return "height: 5px";
            }
        },
        getAccuracyStylePlain(hour) {
            if (!hour) {
                return "";
            }
            let background = ""; // must be set to some value ... otherwise we get undefined and the styles do not update properly...
            const min = this.minDiffForAccuracyStyling;
            const imprecise =
                // new Date().getTime() >= (new Date(hour.hour).getTime() + 60*60*1000) && // TODO what was this about?
                (hour.measurementRecordCount === 0
                || (hour.measurementRecordCount > 0
                    && Math.abs(Math.abs(hour.avgMeasuredCloudTotal - hour.avgForecastCloudTotal) - Math.abs(hour.avgDiff)) > 18));
            if (imprecise) {
                background = ForecastColors.noComparisonColor;
            } else {
                let abs = Math.abs(hour.avgDiff);
                if (abs > min) {
                    let step;
                    if (hour.avgDiff > 0) {
                        step = (100 - 1) / ForecastColors.shadesOfRed.length;
                        background = (ForecastColors.shadesOfRed)[Math.floor((abs - min) / step)];
                    } else {
                        // Math.floor((8 - 6) / ((100 - 6) / 8))
                        step = (100 - 1) / ForecastColors.shadesOfGreen.length;
                        background = (ForecastColors.shadesOfGreen)[Math.floor((abs - min) / step)];
                    }
                }
            }
            return "backgroundColor: " + background + "; height: 5px";
        },
        getAccuracyStyleAbs(hour) {
            if (!hour) {
                return "";
            }
            let background = ""; // must be set to some value ... otherwise we get undefined and the styles do not update properly...
            const min = this.minDiffForAccuracyStyling;
            // criteria for imprecision can be the same as with the plain diff ...
            const imprecise = new Date().getTime() >= (new Date(hour.hour).getTime() + 60*60*1000)
                && (!hour.avgMeasuredCloudTotal
                    || (hour.measurementRecordCount > 0
                        && Math.abs(Math.abs(hour.avgMeasuredCloudTotal - hour.avgForecastCloudTotal) - Math.abs(hour.avgDiff)) > 10));
            if (imprecise) {
                background = ForecastColors.noComparisonColor;
            } else {
                let abs = Math.abs(hour.avgDiffAbs);
                if (abs > min) {
                    let step = (100 - 1) / ForecastColors.shadesOfOrange.length;
                    background = (ForecastColors.shadesOfOrange)[Math.floor((abs - min) / step)];
                }
            }
            return "backgroundColor: " + background + "; height: 5px";
        },
        isDisplayedToday() {
            return new Date(this.getForecastDate).getDate() === new Date().getDate();
        }
    },
    components: {
        VerifiedForecastsLegend, Tooltip
    }
}
</script>

<style scoped>

table {
    border-collapse: collapse;
    overflow-x: auto; /* enable horizontal scrolling */
    /*TODO with this enabled the programtic scrolling does not work*/
    /*display: block; !* important for sticky positioning *!*/
}

th:first-child,
td:first-child {
    position: sticky;
    left: 0;
    z-index: 1; /* make sure the sticky column is on top */
    background-color: #413f3f; /* set the background color to match the table */
}

td, th {
    border: 1px solid #7e7c7c;
    width: 30px; /* Adjust the width as needed */
    text-align: center;
    font-size: 12px;
}

th {
    font-weight: normal;
}

.measurements-top {
    border-top: 3px solid #5e5e5e
}

.award {
    font-size: 15px;
    margin-left: 3px;
}

/*must be here to be picked up*/
.move-day {
    cursor: pointer;
    padding: 8px;
    background-color: #f5f5f5;
    /*color: #6da5fd;*/
    color: #086cd2;
}

/*must be here to be picked up*/
.date {
    height: 41px;
    font-size: 25px; /* specify the font size to match the dimensions */
}

</style>

